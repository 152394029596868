import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import './fonts.css';
import lionxLogo from './assets/lionx-logo.png';
import pattern from './assets/pattern.png';
import rulesBg from './assets/rulesbg.png'; // Add this line to import the rulesbg image
import onenoBg from './assets/01nobg.png';
import twonoBg from './assets/02nobg.png';
import threenoBg from './assets/03nobg.png';
import oneBg from './assets/01bg.png';
import fournoBg from './assets/04nobg.png';
import culBgind from './assets/culturebgind.png';
import cultureInfog from './assets/cultureinfog.png';
import numberBg from './assets/01nobgsmall.png';
import numberBg2 from './assets/02nobgsmall.png';
import numberBg3 from './assets/03nobgsmall.png';
import numberBg4 from './assets/04nobgsmall.png';
import mugImage from './assets/mug.png';
import marhabaImage from './assets/marhaba.png';
import majlisImage from './assets/majlis.png';
import GartnerPreview from './assets/GartnerPreview.png';
import CvPreview from './assets/CvPreview.png';
import HypeDiagram from './assets/HypeDiagram.png';

// Welcome Component
const Welcome00 = () => {
  const [teamName, setTeamName] = useState('');
  const navigate = useNavigate();

  const handleBeginHunt = () => {
    if (teamName.trim()) {
      localStorage.setItem('teamName', teamName);
      navigate('/welcome/01');
    } else {
      alert('Please enter your team name.');
    }
  };

  return (
    <div 
  className="min-h-screen bg-black flex flex-col items-center justify-center text-white"
  style={{
    backgroundImage: `url(${pattern})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }}
>
  <div className="mb-8">
    <img src={lionxLogo} alt="LIONX" className="h-12" />
  </div>
  <h1 className="text-4xl font-bold mb-4">Welcome to the HUNT</h1>
  <h2 className="text-3xl mb-8">Training ground!</h2>
  <div className="mb-6">
    <label htmlFor="teamName" className="block mb-2">What's your team's name?</label>
    <input
      type="text"
      id="teamName"
      value={teamName}
      onChange={(e) => setTeamName(e.target.value)}
      className="bg-teal-400 text-black px-4 py-2 w-64 text-center"
      placeholder="Enter team name"
    />
  </div>
  <button
  onClick={handleBeginHunt}
  className="relative bg-transparent border-0 text-white font-regular px-6 py-2 text-lg tracking-widest uppercase group inline-flex flex-col items-center"
>
  <span className="relative z-10">BEGIN THE HUNT →</span>
  <div className="w-full h-5 relative">
    <span className="absolute h-0.5 bg-purple-400 bottom-3 left-0 right-0"></span>
    <span className="absolute w-0.5 h-full bg-pink-500 left-1/2 -translate-x-1/2 rotate-[45deg]"></span>
  </div>
</button>
</div>

  );
};

// Welcome01 Component
const Welcome01 = () => {
  const navigate = useNavigate();

  const handleUnderstood = () => {
    navigate('/culture');
  };

  return (
    <div className="min-h-screen bg-black flex items-center justify-center">
      <div 
        className="p-8 max-w-2xl w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${rulesBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <h1 className="text-4xl font-bold mb-6 text-white">The Rules</h1>
        <p className="text-white mb-4">Quick reminder before we begin:</p>
        <ol className="list-decimal list-inside text-white space-y-2 mb-6">
          <li>Use your tablet to scan the QR code and reveal the question</li>
          <li>Submitting your answer to the question on the tablet, revealing your next clue.</li>
          <li>The next clue might just take you to another location, so keep an eye out ;)</li>
          <li>Solve all five Training Ground levels to unlock your Unique Team Code!</li>
        </ol>
        <p className="text-white mb-6">P.S. The first team to unlock their unique team code gets a special prize - best of luck!</p>
        <button
          onClick={handleUnderstood}
          className="bg-transparent border-b-2 border-white text-white px-4 py-2 hover:bg-white hover:text-black transition-colors"
        >
          UNDERSTOOD →
        </button>
      </div>
    </div>
  );
};


// Culture Component
const Culture = () => {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState('');
  const handleNext = () => {

    // Navigate to the next screen
    navigate('/culture/00');
  };
  return (
    <div className="min-h-screen bg-black text-white py-16 flex flex-col items-center">
      <div className="px-8 mb-8 w-full max-w-3xl text-center">
        <h1 className="text-2xl mb-4">
          We've all heard of the hype around AI, but
          AI is just one piece of the puzzle—
          Computer Vision is the bigger innovation.
        </h1>
        <p className="text-[#B66EFF] text-xl mb-6">
          Computer vision is a field of computer
          science that enables machines to interpret
          and understand the visual world.
        </p>
        <p className="text-xl">
          Ready to get into it?
        </p>
      </div>
      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-[#B66EFF] transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#B66EFF]"></span>
        </span>
      </button>
    </div>
  );
};

// Culture00 Component
const Culture00 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/culture/01');
  };

  return (
    <div 
    className="min-h-screen bg-black flex flex-col items-center justify-center text-white relative"
    style={{ backgroundImage: `url(${oneBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
  >

  
    <div className="z-10 text-center">
      {/* Use nooneBg image for the "01" text on top */}
      <div 
        className="w-24 h-24 mx-auto mb-6 flex items-center justify-center" 
        style={{ 
          backgroundImage: `url(${onenoBg})`, // keeping the background image for the "01" block
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <span className="text-4xl font-bold">01</span>
      </div>
      
      <h1 className="text-5xl font-bold mb-4">The 5 Elements</h1>
      <h2 className="text-5xl font-bold">of Culture</h2>
    </div>
  
    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};

// Culture01 Component
const Culture01 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/culture/02');
  };

  const elements = [
    {
      name: 'Symbols',
      definition: 'Objects or gestures that represent deeper meanings within a culture.',
      example: 'Example: The falcon symbolizes strength and pride in the UAE.'
    },
    {
      name: 'Language',
      definition: 'A system of symbols used for communication, shaping perceptions and connections.',
      example: 'Example: English and French words for "chair" convey the same object differently.'
    },
    {
      name: 'Norms',
      definition: 'Accepted standards and expectations for behavior within a society.',
      example: 'Example: In India, it is customary to eat with your right hand.'
    },
    {
      name: 'Values',
      definition: 'Core beliefs about what is good, important, or desirable in a society.',
      example: 'Example: In Japan, group harmony is a highly valued principle.'
    },
    {
      name: 'Artifacts',
      definition: 'Core beliefs about what is good, important, or desirable in a society.',
      example: 'Example: In Japan, group harmony is a highly valued principle.'
    }
  ];

  return (
    <div className="min-h-screen bg-black text-white py-16">
    <div className="px-8 mb-8">
      <div className="flex items-center">
        <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
          <span className="text-2xl font-bold text-white">01</span>
        </div>
        <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
      </div>
    </div>

    <div className="mb-8">
      <img src={cultureInfog} alt="5 Elements of Culture" className="w-full" />
    </div>

    <div className="px-8">
      <div className="grid grid-cols-5 gap-4">
        {elements.map((element, index) => (
          <div key={index} className="text-sm">
            <p className="mb-2">{element.definition}</p>
            <p>{element.example}</p>
          </div>
        ))}
      </div>
    </div>

    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};

// Culture02 Component

const Culture02 = () => {
  const navigate = useNavigate();

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (selectedAnswer === 'Symbols') {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/culture/03');
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
            <span className="text-2xl font-bold text-white">01</span>
          </div>
          <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
        </div>
      </div>

      <div className="px-8 flex flex-col items-center">
        <img src={mugImage} alt="Coffee Mug" className="w-1/3 max-w-xs mb-8" />
        <h2 className="text-xl font-bold mb-4">
          <span className="text-[#B66EFF]">Which element of culture is this?</span> 
        </h2>
        <div className="grid grid-cols-1 gap-4 w-full max-w-xs">
          {['Norms', 'Values', 'Symbols'].map((option) => (
            <button
              key={option}
              onClick={() => handleAnswer(option)}
              className={`w-full p-2 border border-[#10DDD5] text-white ${
                selectedAnswer === option ? 'bg-[#10DDD5]' : 'bg-transparent'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <button
        onClick={handleNext}
        className="text-white hover:text-purple-400 transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>

      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};

// Culture03 Component
const Culture03 = () => {
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (inputValue.toLowerCase() === "where's the hype") {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/gartner/00');
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="min-h-screen bg-black text-white py-16">
        <div className="px-8 mb-8">
          <div className="flex items-center">
            <div
              className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain"
              style={{ backgroundImage: `url(${numberBg})` }}
            >
              <span className="text-2xl font-bold text-white">
                {"01".padStart(2, '0')}
              </span>
            </div>
            <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
          </div>
        </div>

        <div className="px-8 flex flex-col items-center">
          <h2 className="text-xl font-bold mb-4">
            <span className="text-[#E820A6]">
              Well done on solving your first puzzle!
            </span>
          </h2>
          <div>
            <p>
              To continue, you might need a pick me up. Where might you find it? Once you're there, find and scan the QR code with your phone and receive a password that will get you into the next training round.
            </p>
            <div className="relative">
              <input
                type="text" // Changed from "password" to "text"
                value={inputValue.toUpperCase()} // Ensures all caps
                onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                className="w-full p-2 mt-4 outline-none transition-colors duration-200 border uppercase"
                style={{
                  borderColor: '#B66EFF',
                  backgroundColor: 'transparent',
                  color: '#10DDD5', // Changed font color
                  fontFamily: 'monospace' // Optional: ensures a fixed-width font for passwords
                }}
                placeholder="Password"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleNext}
        className="text-white hover:text-purple-400 transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          UNLOCK →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>
      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};

// Gartner00 Component
const Gartner00 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/gartner/01');
  };

  return (
    <div 
    className="min-h-screen bg-black flex flex-col items-center justify-center text-white relative"
    style={{ backgroundImage: `url(${oneBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
  >

  
    <div className="z-10 text-center">
      {/* Use nooneBg image for the "01" text on top */}
      <div 
        className="w-24 h-24 mx-auto mb-6 flex items-center justify-center" 
        style={{ 
          backgroundImage: `url(${twonoBg})`, // keeping the background image for the "01" block
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <span className="text-4xl font-bold">02</span>
      </div>
      
      <h1 className="text-5xl font-bold mb-4">Gartner Hype Cycle</h1>
    </div>
  
    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};


// Gartner01 Component
const Gartner01 = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const iframeRef = useRef(null);

  const handleNext = () => {
    navigate('/gartner/02');
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleReplay = () => {
    if (iframeRef.current) {
      // Reload the iframe to restart the video
      iframeRef.current.src = iframeRef.current.src;
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg2})` }}>
            <span className="text-2xl font-bold text-white">02</span>
          </div>
          <h1 className="text-3xl font-bold">Gartner Hype Cycle</h1>
        </div>
      </div>

      {/* Video Preview or YouTube Embed */}
      <div className="flex justify-center mb-8">
        {!isPlaying ? (
          <div className="relative cursor-pointer" onClick={handlePlay}>
            <img src={GartnerPreview} alt="Video Preview" className="w-[560px] h-[315px] object-cover" />
            <div className="absolute inset-0 flex items-center justify-center">
              <svg className="w-20 h-20 text-[#10DDD5] opacity-80" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jB1RDz9jaj0?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>

      {/* Replay Button */}
      <button
        onClick={handleReplay}
        className="absolute bottom-8 left-8 text-white hover:text-[#10DDD5] transition-colors"
      >
        <span className="relative inline-block">
          REPLAY ↺
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10DDD5]"></span>
        </span>
      </button>

      {/* Next Button */}
      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-[#10DDD5] transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10DDD5]"></span>
        </span>
      </button>
    </div>
  );
};

// Gartner02 Component

const Gartner02 = () => {
  const navigate = useNavigate();

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (selectedAnswer === 'Peak of Inflated Expectations') {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/gartner/03'); // Adjust this path as needed
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  const options = [
    'Innovation Trigger',
    'Peak of Inflated Expectations',
    'Trough of Disillusionment',
    'Slope of Enlightenment',
    'Plateau of Productivity'
  ];

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg2})` }}>
            <span className="text-2xl font-bold text-white">02</span>
          </div>
          <h1 className="text-3xl font-bold">Gartner Hype Cycle</h1>
        </div>
      </div>

      <div className="px-8 flex flex-col items-center">
      <h2 className="text-xl font-bold mb-4">
          <span className="text-[#10DDD5]">Where do you think Gen AI sits in the Hype Cycle?</span> 
        </h2>
        <img src={HypeDiagram} alt="Hype Cycle Diagram" className="w-2/3 max-w-2xl mb-8" />
       
        <div className="grid grid-cols-1 gap-4 w-full max-w-md">
          {options.map((option) => (
            <button
              key={option}
              onClick={() => handleAnswer(option)}
              className={`w-full p-2 border border-[#10DDD5] text-white ${
                selectedAnswer === option ? 'bg-[#10DDD5]' : 'bg-transparent'
              }`}
            >
              {option}
            </button>
          ))}
        </div>
      </div>

      <button
        onClick={handleNext}
        className="text-white hover:text-[#10DDD5] transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10DDD5]"></span>
        </span>
      </button>

      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};


// Gartner03 Component

const Gartner03 = () => {
  const navigate = useNavigate();

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (inputValue.toLowerCase() === 'key3') {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/theory/00');
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="min-h-screen bg-black text-white py-16">
        <div className="px-8 mb-8">
          <div className="flex items-center">
            <div
              className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain"
              style={{ backgroundImage: `url(${numberBg2})` }}
            >
              <span className="text-2xl font-bold text-white">
                {"02".padStart(2, '0')}
              </span>
            </div>
            <h1 className="text-3xl font-bold">Gartner Hype Cycle</h1>
          </div>
        </div>

        <div className="px-8 flex flex-col items-center">
          <h2 className="text-xl font-bold mb-4">
            <span className="text-[#E820A6]">Congrats!</span>
          </h2>
          <div>
            <p>
              To continue, look for a lion that looks surprised. Find and scan the QR code with your phone and receive a password that will get you into the next training round.
            </p>
            <div className="relative">
              <input
                type="text" // Change from "password" to "text" to show visible input
                value={inputValue.toUpperCase()} // Ensure the input is always in uppercase
                onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                className="w-full p-2 mt-4 outline-none transition-colors duration-200 border uppercase"
                style={{
                  borderColor: '#B66EFF',
                  backgroundColor: 'transparent',
                  color: '#10DDD5', // Set text color
                }}
                placeholder="Password"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={handleNext}
        className="text-white hover:text-purple-400 transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          UNLOCK →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>

      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};


// Theory00 Component
const Theory00 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/theory/01');
  };

  return (
    <div 
    className="min-h-screen bg-black flex flex-col items-center justify-center text-white relative"
    style={{ backgroundImage: `url(${oneBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
  >

  
    <div className="z-10 text-center">
      {/* Use nooneBg image for the "01" text on top */}
      <div 
        className="w-24 h-24 mx-auto mb-6 flex items-center justify-center" 
        style={{ 
          backgroundImage: `url(${threenoBg})`, // keeping the background image for the "01" block
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <span className="text-4xl font-bold">03</span>
      </div>
      
      <h1 className="text-5xl font-bold mb-4">Consumer</h1>
      <h2 className="text-5xl font-bold">Culture Theory</h2>
    </div>
  
    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};



// Theory01 Component
const Theory01 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/theory/02');
  };



  return (
    <div className="min-h-screen bg-black text-white py-16">
    <div className="px-8 mb-8">
      <div className="flex items-center">
        <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg3})` }}>
          <span className="text-2xl font-bold text-white">03</span>
        </div>
        <h1 className="text-3xl font-bold">Consumer Culture Theory</h1>
      </div>
    </div>

    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};


// Theory02 Component
const Theory02 = () => {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(5 * 60); // 5 minutes in seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleNext = () => {
    navigate('/theory/03'); // Assuming the next page is 03
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 flex flex-col">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg3})` }}>
            <span className="text-2xl font-bold text-white">03</span>
          </div>
          <h1 className="text-3xl font-bold">Consumer Culture Theory</h1>
        </div>
      </div>
      
      <div className="flex-grow flex flex-col items-center justify-center px-8">
        <p className="text-center mb-8 max-w-2xl">
          Discuss smart wearables, what hindered their growth, which area are they likely to be most successful in upcoming years
        </p>
        <div className="text-6xl font-bold">
          {formatTime(timeLeft)}
        </div>
      </div>

      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>
    </div>
  );
};


// Theory03 Component
const Theory03 = () => {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState('');

  const handleNext = () => {
    // Here you can add logic to process the answer
    console.log('Stored answer:', answer);
    // You can also store it in a more persistent state management solution if needed

    // Navigate to the next screen
    navigate('/theory/04');
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 flex flex-col items-center">
      <div className="px-8 mb-8 w-full">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg3})` }}>
            <span className="text-2xl font-bold text-white">03</span>
          </div>
          <h1 className="text-3xl font-bold">Consumer Culture Theory</h1>
        </div>
      </div>

      <div className="flex-grow flex flex-col items-center justify-center">
        <h2 className="text-xl mb-4">Submit answer to area of success</h2>
        <div className="w-full max-w-md">
          <input
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            className="w-full p-2 text-black rounded"
            placeholder="Enter your answer"
          />
        </div>
      </div>

      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>
    </div>
  );
};


// Theory04 Component
const Theory04 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/theory/05');
  };



  return (
    <div className="min-h-screen bg-black text-white py-16">
    <div className="px-8 mb-8">
      <div className="flex items-center">
        <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg3})` }}>
          <span className="text-2xl font-bold text-white">03</span>
        </div>
        <h1 className="text-3xl font-bold">Consumer Culture Theory</h1>
      </div>
    </div>

    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};


// Theory05 Component
const Theory05 = () => {
  const navigate = useNavigate();

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (inputValue.toLowerCase() === 'key5') {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/cv/00');
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div
            className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url(${numberBg3})` }}
          >
            <span className="text-2xl font-bold text-white">
              {"03".padStart(2, '0')}
            </span>
          </div>
          <h1 className="text-3xl font-bold">Consumer Culture Theory</h1>
        </div>
      </div>

      <div className="px-8 flex flex-col items-center">
        <h2 className="text-xl font-bold mb-4">
          <span className="text-[#E820A6]">Super, you're halfway there!</span>
        </h2>
        <div>
          <p>Getting hungry here, wonder what's for lunch?</p>
          <div className="relative">
            <input
              type="text" // Change from "password" to "text" to make the input visible
              value={inputValue.toUpperCase()} // Ensure the input is always in uppercase
              onChange={(e) => setInputValue(e.target.value.toUpperCase())}
              className="w-full p-2 mt-4 outline-none transition-colors duration-200 border uppercase"
              style={{
                borderColor: '#B66EFF',
                backgroundColor: 'transparent',
                color: '#10DDD5', // Set text color
              }}
              placeholder="Password"
            />
          </div>
        </div>
      </div>

      <button
        onClick={handleNext}
        className="text-white hover:text-purple-400 transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          UNLOCK →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>

      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};


// CV00 Component
const Cv00 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/cv/01');
  };

  return (
    <div 
    className="min-h-screen bg-black flex flex-col items-center justify-center text-white relative"
    style={{ backgroundImage: `url(${oneBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
  >

  
    <div className="z-10 text-center">
      {/* Use nooneBg image for the "01" text on top */}
      <div 
        className="w-24 h-24 mx-auto mb-6 flex items-center justify-center" 
        style={{ 
          backgroundImage: `url(${fournoBg})`, // keeping the background image for the "01" block
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <span className="text-4xl font-bold">04</span>
      </div>
      
      <h1 className="text-5xl font-bold mb-4">Computer Vision</h1>
    </div>
  
    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};



// CV01 Component
const Cv01 = () => {
  const navigate = useNavigate();
  const [isPlaying, setIsPlaying] = useState(false);
  const iframeRef = useRef(null);

  const handleNext = () => {
    navigate('/cv/02');
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handleReplay = () => {
    if (iframeRef.current) {
      // Reload the iframe to restart the video
      iframeRef.current.src = iframeRef.current.src;
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 relative">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg4})` }}>
            <span className="text-2xl font-bold text-white">04</span>
          </div>
          <h1 className="text-3xl font-bold">Computer Vision</h1>
        </div>
      </div>

      {/* Video Preview or YouTube Embed */}
      <div className="flex justify-center mb-8">
        {!isPlaying ? (
          <div className="relative cursor-pointer" onClick={handlePlay}>
            <img src={CvPreview} alt="Video Preview" className="w-[560px] h-[315px] object-cover" />
            <div className="absolute inset-0 flex items-center justify-center">
              <svg className="w-20 h-20 text-[#10DDD5] opacity-80" fill="currentColor" viewBox="0 0 20 20">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        ) : (
          <iframe
            ref={iframeRef}
            width="560"
            height="315"
            src="https://www.youtube.com/embed/OnTgbN3uXvw?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>

      {/* Replay Button */}
      <button
        onClick={handleReplay}
        className="absolute bottom-8 left-8 text-white hover:text-[#10DDD5] transition-colors"
      >
        <span className="relative inline-block">
          REPLAY ↺
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10DDD5]"></span>
        </span>
      </button>

      {/* Next Button */}
      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-[#10DDD5] transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-[#10DDD5]"></span>
        </span>
      </button>
    </div>
  );
};


// Cv02 Component
const Cv02 = () => {
  const navigate = useNavigate();
  const [answer, setAnswer] = useState('');

  const handleNext = () => {
    // Here you can add logic to process the answer
    console.log('Stored answer:', answer);
    // You can also store it in a more persistent state management solution if needed

    // Navigate to the next screen
    navigate('/cv/03');
  };

  return (
    <div className="min-h-screen bg-black text-white py-16 flex flex-col items-center">
      <div className="px-8 mb-8 w-full">
        <div className="flex items-center">
          <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg4})` }}>
            <span className="text-2xl font-bold text-white">04</span>
          </div>
          <h1 className="text-3xl font-bold">Computer Vision</h1>
        </div>
      </div>

      <div className="flex-grow flex flex-col items-center justify-center">
        <h2 className="text-xl mb-4">Scan the food menu with Google Lens. <br></br> Enter the translation</h2>
        <div className="w-full max-w-md">
          <input
            type="text"
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            className="w-full p-2 text-black rounded"
            placeholder="Enter your answer"
          />
        </div>
      </div>

      <button
        onClick={handleNext}
        className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
      >
        <span className="relative inline-block">
          NEXT →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>
    </div>
  );
};


// Cv03 Component

const Cv03 = () => {
  const navigate = useNavigate();

  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleNext = () => {
    if (inputValue.toLowerCase() === 'key6') {
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        navigate('/dimensions/00');
      }, 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white py-16">
      <div className="px-8 mb-8">
        <div className="flex items-center">
          <div
            className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain"
            style={{ backgroundImage: `url(${numberBg4})` }}
          >
            <span className="text-2xl font-bold text-white">
              {"04".padStart(2, '0')}
            </span>
          </div>
          <h1 className="text-3xl font-bold">Computer Vision</h1>
        </div>
      </div>

      <div className="px-8 flex flex-col items-center">
        <h2 className="text-xl font-bold mb-4">
          <span className="text-[#E820A6]">
            Great job!<br />
            <br />
            You are almost there.
          </span>
        </h2>
        <div>
          <p>
            Now, look for an inspiring quote about imagination! Scan the QR code
            with your phone and receive a password that will get you into the
            next training round.
          </p>
          <div className="relative">
            <input
              type="text" // Change from "password" to "text" to make the input visible
              value={inputValue.toUpperCase()} // Ensure the input is always in uppercase
              onChange={(e) => setInputValue(e.target.value.toUpperCase())}
              className="w-full p-2 mt-4 outline-none transition-colors duration-200 border uppercase"
              style={{
                borderColor: '#B66EFF',
                backgroundColor: 'transparent',
                color: '#10DDD5', // Set text color
              }}
              placeholder="Password"
            />
          </div>
        </div>
      </div>

      <button
        onClick={handleNext}
        className="text-white hover:text-purple-400 transition-colors absolute bottom-8 right-8"
      >
        <span className="relative inline-block">
          UNLOCK →
          <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
        </span>
      </button>

      {showError && (
        <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
          Incorrect. Please try again.
        </div>
      )}
      {showSuccess && (
        <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          Correct! Moving to the next page.
        </div>
      )}
    </div>
  );
};


// Dimensions00 Component
const Dimensions00 = () => {
  const navigate = useNavigate();

  const handleNext = () => {
    navigate('/dimensions/01');
  };

  return (
    <div 
    className="min-h-screen bg-black flex flex-col items-center justify-center text-white relative"
    style={{ backgroundImage: `url(${oneBg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
  >

  
    <div className="z-10 text-center">
      {/* Use nooneBg image for the "01" text on top */}
      <div 
        className="w-24 h-24 mx-auto mb-6 flex items-center justify-center" 
        style={{ 
          backgroundImage: `url(${twonoBg})`, // keeping the background image for the "01" block
          backgroundSize: 'contain', 
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat' 
        }}
      >
        <span className="text-4xl font-bold">05</span>
      </div>
      
      <h1 className="text-5xl font-bold mb-4">4 Dimensions of Creativity</h1>
    </div>
  
    <button
  onClick={handleNext}
  className="absolute bottom-8 right-8 text-white hover:text-purple-400 transition-colors"
>
  <span className="relative inline-block">
    NEXT →
    <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
  </span>
</button>
  </div>
  );
};







// QuizGame Component
const QuizGame = () => {
  const navigate = useNavigate();
  const handleGround02 = () => {
    navigate('/ground02');
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pinPosition, setPinPosition] = useState({ x: 0, y: 0 });
  const [inputValue, setInputValue] = useState('');
  const [coffeeIdeas, setCoffeeIdeas] = useState([]);
  const [timeLeft, setTimeLeft] = useState(180); // 3 minutes in seconds
  const imageRef = useRef(null);

  useEffect(() => {
    let timer;
    if (currentPage === 13 && timeLeft > 0 && coffeeIdeas.length < 10) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0 || coffeeIdeas.length === 10) {
      setCurrentPage(14);
    }
    return () => clearInterval(timer);
  }, [currentPage, timeLeft, coffeeIdeas]);

  const handleAnswer = (answer) => {
    setSelectedAnswer(answer);
  };

  const handleImageClick = (e) => {
    if (imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      setPinPosition({ x, y });
    }
  };

  const handleNext = () => {
    if (currentPage === 1 && selectedAnswer === 'Symbols') {
      setCurrentPage(2);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 2 && selectedAnswer === 'Languages') {
      setCurrentPage(3);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 3 && selectedAnswer === 'Norms') {
      setCurrentPage(4);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 4 && inputValue.toLowerCase() === 'key2') {
      setCurrentPage(5);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 5 || currentPage === 6 || currentPage === 7) {
      setCurrentPage(currentPage + 1);
    } else if (currentPage === 8 && selectedAnswer === 'a') {
      setCurrentPage(9);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 9 && inputValue.toLowerCase() === 'key3') {
      setCurrentPage(10);
      setInputValue('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 10) {
      setCurrentPage(11);
    } else if (currentPage === 11 && selectedAnswer === 'b') {
      setCurrentPage(12);
      setSelectedAnswer('');
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else if (currentPage === 12 && inputValue.toLowerCase() === 'key4') {
      setCurrentPage(13);
      setInputValue('');
      setTimeLeft(180);
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 2000);
    } else {
      setShowError(true);
      setTimeout(() => setShowError(false), 2000);
    }
  };

  const handleCoffeeIdea = () => {
    if (inputValue.trim() !== '' && coffeeIdeas.length < 10) {
      setCoffeeIdeas([...coffeeIdeas, inputValue]);
      setInputValue('');
      if (coffeeIdeas.length === 9) {
        setCurrentPage(14);
      }
    }
  };

  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <div className="min-h-screen bg-black text-white py-16">
  <div className="px-8 mb-8">
    <div className="flex items-center">
      <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
        <span className="text-2xl font-bold text-white">{String(currentPage).padStart(2, '0')}</span>
      </div>
      <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
    </div>
  </div>

  <div className="px-8 flex flex-col items-center">
    <img src={mugImage} alt="Coffee Mug" className="w-1/3 max-w-xs mb-8" />
    <h2 className="text-xl font-bold mb-4">
      <span className="text-[#B66EFF]">Which element of culture is this?</span> 
    </h2>
    <div className="grid grid-cols-1 gap-4 w-full max-w-xs">
      {['Norms', 'Values', 'Symbols'].map((option) => (
        <button
          key={option}
          onClick={() => handleAnswer(option)}
          className={`w-full p-2 border border-[#10DDD5] text-white ${
            selectedAnswer === option ? 'bg-[#10DDD5]' : 'bg-transparent'
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  </div>
</div>
                  );
      case 2:
        return (
          <div className="min-h-screen bg-black text-white py-16">
  <div className="px-8 mb-8">
    <div className="flex items-center">
      <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
        <span className="text-2xl font-bold text-white">{String(currentPage).padStart(2, '0')}</span>
      </div>
      <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
    </div>
  </div>

  <div className="px-8 flex flex-col items-center">
    <img src={marhabaImage} alt="Marhaba" className="w-1/3 max-w-xs mb-8" />
    <h2 className="text-xl font-bold mb-4">
      <span className="text-[#B66EFF]">Which element of culture is this?</span> 
    </h2>
    <div className="grid grid-cols-1 gap-4 w-full max-w-xs">
      {['Values', 'Languages', 'Artifacts'].map((option) => (
        <button
          key={option}
          onClick={() => handleAnswer(option)}
          className={`w-full p-2 border border-[#10DDD5] text-white ${
            selectedAnswer === option ? 'bg-[#10DDD5]' : 'bg-transparent'
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  </div>
</div>
                  );
      case 3:
        return (
<div className="min-h-screen bg-black text-white py-16">
  <div className="px-8 mb-8">
    <div className="flex items-center">
      <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
        <span className="text-2xl font-bold text-white">{String(currentPage).padStart(2, '0')}</span>
      </div>
      <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
    </div>
  </div>

  <div className="px-8 flex flex-col items-center">
    <img src={majlisImage} alt="Majlis" className="w-1/3 max-w-xs mb-8" />
    <h2 className="text-xl font-bold mb-4">
      <span className="text-[#B66EFF]">Which element of culture is this?</span> 
    </h2>
    <div className="grid grid-cols-1 gap-4 w-full max-w-xs">
      {['Norms', 'Values', 'Symbols'].map((option) => (
        <button
          key={option}
          onClick={() => handleAnswer(option)}
          className={`w-full p-2 border border-[#10DDD5] text-white ${
            selectedAnswer === option ? 'bg-[#10DDD5]' : 'bg-transparent'
          }`}
        >
          {option}
        </button>
      ))}
    </div>
  </div>
</div>
        );
      case 4:
        return (
          <div className="min-h-screen bg-black text-white py-16">
          <div className="px-8 mb-8">
            <div className="flex items-center">
              <div className="w-12 h-12 flex items-center justify-center mr-4 bg-no-repeat bg-center bg-contain" style={{ backgroundImage: `url(${numberBg})` }}>
                <span className="text-2xl font-bold text-white">{"01".padStart(2, '0')}</span>
              </div>
              <h1 className="text-3xl font-bold">The 5 Elements of Culture</h1>
            </div>
          </div>
        
          <div className="px-8 flex flex-col items-center">
     
          <h2 className="text-xl font-bold mb-4">
              <span className="text-[#E820A6]">Well done on solving your first puzzle!</span> 
            </h2>
            <div>            <p>To continue, you might need a pick me up. Where might you find it? Once you're there, find and scan the QR code with your phone and receive a password that will get you into the next training round.</p>
            <div className="relative">
            <input
  type="password"
  value={inputValue}
  onChange={(e) => setInputValue(e.target.value)}
  className="w-full p-2 mt-4 outline-none transition-colors duration-200 border"
  style={{
    borderColor: '#B66EFF',
    backgroundColor: 'transparent',
    color: '#10DDD5'
  }}
  placeholder="Password"
/>

    </div>
          </div>
  
          </div>
        </div>
        );
      case 5:
      case 7:
      case 10:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Video Content</h2>
            <p>The video would autoplay here.</p>
            <p>Due to content restrictions, we can't embed the video directly. Please visit the YouTube link to watch the video.</p>
            <a 
              href={currentPage === 5 ? "https://www.youtube.com/watch?v=jB1RDz9jaj0" : 
                    currentPage === 7 ? "https://youtu.be/hFpL__zTQWM" :
                    "https://youtu.be/puB-4LuRNys"}
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-500 underline"
            >
              Watch the video on YouTube
            </a>
          </div>
        );
      case 6:
        return (
          <div className="relative">
            <img
              ref={imageRef}
              src="https://miro.medium.com/v2/resize:fit:960/1*mG1Y8k_4Ly2xDtLQBimctA.png"
              alt="Interactive image"
              className="w-full cursor-crosshair"
              onClick={handleImageClick}
            />
            {pinPosition.x !== 0 && pinPosition.y !== 0 && (
              <div
                className="absolute w-4 h-4 bg-red-500 rounded-full transform -translate-x-1/2 -translate-y-1/2"
                style={{ left: pinPosition.x, top: pinPosition.y }}
              ></div>
            )}
            <p className="mt-4">Click on the image to place a pin. The pin should be within the central two-thirds of the image.</p>
          </div>
        );
      case 8:
      case 11:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">
              Question: Question: {currentPage === 8 ? "Which area will wearable contribute the most success?" : "Which hidden message is shown on the second page of the menu?"}
            </h2>
            <div className="space-y-2">
              {['a', 'b', 'c', 'd'].map((option) => (
                <button
                  key={option}
                  onClick={() => handleAnswer(option)}
                  className={`w-full p-2 border ${selectedAnswer === option ? 'bg-blue-500 text-white' : 'bg-white'}`}
                >
                  Option {option.toUpperCase()}
                </button>
              ))}
            </div>
          </div>
        );
      case 9:
      case 12:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">{currentPage === 9 ? "Super, you're halfway there!" : "Great job! You are almost there."}</h2>
            <p>{currentPage === 9 ? 
              "Getting hungry here, wonder what's for lunch? Scan the QR code on the lunch menu through your phone to receive the passcode for the next section." : 
              "Now, look for an inspiring quote about imagination! Scan the QR code with your phone and receive a password that will get you into the next training round"}
            </p>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 border mt-4"
              placeholder="Password"
            />
          </div>
        );
      case 13:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Can you imagine 10 ways you can use coffee beans (apart from drinking it!)? You have {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? '0' : ''}{timeLeft % 60} left!</h2>
            <input
              type="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              className="w-full p-2 border mt-4"
              placeholder="Enter an idea"
              disabled={coffeeIdeas.length >= 10}
            />
            <button 
              onClick={handleCoffeeIdea} 
              className={`w-full p-2 ${coffeeIdeas.length < 10 ? 'bg-blue-500' : 'bg-gray-500'} text-white mt-4`}
              disabled={coffeeIdeas.length >= 10}
            >
              Submit Idea ({coffeeIdeas.length}/10)
            </button>
            <ul className="list-disc pl-5 mt-4">
              {coffeeIdeas.map((idea, index) => (
                <li key={index}>{idea}</li>
              ))}
            </ul>
          </div>
        );
      case 14:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">Congratulations!</h2>
            <p>You have unlocked your part of the code - #2 in 2nd position! Now head to the room and put together the final code to open the treasure trunk</p>
            <button onClick={() => window.close()} className="w-full p-2 bg-green-500 text-white mt-4">
              Finish
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-4 relative">
  {renderPage()}
  {currentPage <= 12 && (
    <button
      onClick={currentPage === 4 ? handleGround02 : handleNext}
      className={`text-white hover:text-purple-400 transition-colors ${
        currentPage === 4 || currentPage === 5
          ? "absolute left-1/2 bottom-8 transform -translate-x-1/2"
          : "absolute bottom-8 right-8"
      }`}
    >
      <span className="relative inline-block">
        {currentPage === 4 || currentPage === 5 ? "UNLOCK" : "NEXT"} →
        <span className="absolute left-0 right-0 bottom-0 h-0.5 bg-purple-400"></span>
      </span>
    </button>
  )}
  {showError && (
    <div className="absolute bottom-8 left-8 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
      Incorrect. Please try again.
    </div>
  )}
  {showSuccess && (
    <div className="absolute bottom-8 left-8 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
      Correct! Moving to the next page.
    </div>
  )}
</div>
  );
};

// Main App Component
function App() {
  return (
    <Router>
      <div className="font-sans">
      <Routes>
        <Route path="/" element={<Welcome00 />} />
        <Route path="/welcome/01" element={<Welcome01 />} />
        <Route path="/culture" element={<Culture />} />
        <Route path="/culture/00" element={<Culture00 />} />
        <Route path="/culture/01" element={<Culture01 />} />
        <Route path="/culture/02" element={<Culture02 />} />
        <Route path="/culture/03" element={<Culture03 />} />
        <Route path="/gartner/00" element={<Gartner00 />} />
        <Route path="/gartner/01" element={<Gartner01 />} />
        <Route path="/gartner/02" element={<Gartner02 />} />
        <Route path="/gartner/03" element={<Gartner03 />} />
        <Route path="/theory/00" element={<Theory00 />} />
        <Route path="/theory/01" element={<Theory01 />} />
        <Route path="/theory/02" element={<Theory02 />} />
        <Route path="/theory/03" element={<Theory03 />} />
        <Route path="/theory/04" element={<Theory04 />} />
        <Route path="/theory/05" element={<Theory05 />} />
        <Route path="/cv/00" element={<Cv00 />} />
        <Route path="/cv/01" element={<Cv01 />} />
        <Route path="/cv/02" element={<Cv02 />} />
        <Route path="/cv/03" element={<Cv03 />} />
        <Route path="/dimensions/00" element={<Dimensions00 />} />
        <Route path="/quiz" element={<QuizGame />} />
      </Routes>
      </div>
    </Router>
  );
};



export default App;